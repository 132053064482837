.img-bg {
  font-size: 14px;
  color: #716e6e;
  font-family: "Raleway", sans-serif !important;
  background: url(../../public/assets/images/bg1.jpg) no-repeat 50% fixed !important;
  background-size: cover !important;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 100px;
  min-height: 100vh;
  position: relative;
  margin: 0;
}
/* Dynamic Css Start  */

.img-bg .white-box {
  border: 4px solid #25408f;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px 30px;
  border-width: 4px 0px;
}

.fix-white {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 90001478523;
}
.img-bg .fix-white {
  display: none;
}

.img-bg .white-box h1 {
  color: #25408f;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.img-bg .white-box.thank {
  text-align: center;
}
.img-bg .white-box-n img {
  margin-bottom: 20px;
}
.img-bg .white-box-n {
  text-align: center;
}
.img-bg .white-box-n button:hover {
  background: #16419c;
  border: 1px solid #223d99;
}
.img-bg .white-box-n button {
  background: #00a8e2;
  border: 1px solid #00a8e2;
  outline: none;
  color: #fff;
  padding: 6px 40px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
}

/* Dynamic Css End  */

html {
  height: 100%;
  box-sizing: border-box;
}

form#signin {
  margin-bottom: 0px;
}

i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

.mt-2 {
  margin-top: 20px;
}
.img-bg a {
  color: #000;
  text-decoration: none;
}
.img-bg a:hover {
  color: #000;
  text-decoration: underline;
}
.img-bg ul.points li:first-child {
  border: none;
}
.img-bg h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.img-bg h2,
.img-bg h3,
.img-bg h4,
.img-bg h5,
.img-bg h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.img-bg .glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}
.img-bg select {
  outline: none !important;
}
.img-bg h1,
.img-bg h2,
.img-bg h3,
.img-bg h4,
.img-bg h5,
.img-bg h6,
.img-bg p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
}
.img-bg p {
  line-height: 20px;
  color: #000;
  font-weight: 400;
}
.img-bg h1 {
  font-size: 30px;
}
.img-bg h2 {
  font-size: 26px;
}
.img-bg h3 {
  font-size: 24px;
}
.img-bg h4 {
  font-size: 20px;
}
.img-bg h5 {
  font-size: 18px;
}
.img-bg h6 {
  font-size: 16px;
}
.img-bg :before,
.img-bg :after {
  font-family: Material-Design-Iconic-Font;
}
.img-bg p.note {
  background: #25408f;
  padding: 14px;
  color: #fff;
}
.img-bg ul.info-data {
  padding: 0px;
  list-style: none;
  margin: 10px auto;
  display: inline-block;
}
.img-bg ul.info-data li {
  border: 1px solid #ccc;
  color: #212121;
  margin-top: -1px;
  padding: 4px 52px;
  font-weight: 400;
}
.img-bg .clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}
.img-bg .check-boexs {
  float: left;
  margin-bottom: 20px;
  width: 24.3% !important;
}
.img-bg .logo {
  /* background: rgba(255, 255, 255, 0.9); */
  /* background: #ffffff; */
  margin: 18px auto;
  padding-top: 2px;
  padding-bottom: 2px;
  /* padding-bottom: 26px; */
}
.img-bg .logo img {
  margin: 0px auto !important;
  width: 40%;
  /* width: 100%; */
}
.img-bg form.form-outer label span.carddetail {
  display: block;
}
.img-bg .red_point {
  color: #f00;
  font-size: 23px;
}
.img-bg .error_message_forimage {
  width: auto !important;
  padding: 0 !important;
  float: left;
}
.img-bg .input-outer {
  position: relative;
  margin: 20px 0px;
}
.img-bg p.logo-text {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
  color: #3e3934;

  margin-top: 13px;
}
.img-bg p.error {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  display: inline-block;
}
.img-bg .footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.img-bg button#pop {
  border-color: #fff0;
}
.img-bg div#inlineContent img {
  width: 300px;
}
.img-bg div#ob_content p {
  text-align: center;
}
.img-bg .white-box.form h2 {
  border: 1px solid #ccc;
  margin-top: 25px;
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
}
.img-bg form.form-outer.register label span {
  color: #f00;
  font-size: 23px;
}
.img-bg .phone-outer input {
  width: 30% !important;
}
.img-bg input#choicekw0 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg input#choicekw1 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg a#userinfo {
  color: #4040bb !important;
  text-decoration: underline !important;
  outline: none !important;
}
.img-bg input#choicekw2 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg div#font-style {
  font-family: var(--bs-font-sans-serif);
}
.img-bg form.form-outer.register select {
  background: #ffffff00;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #231f20;
}
.img-bg input#choicekw3 {
  width: 10%;
  display: block;
  margin: 0 auto;
}
.img-bg .reward1 img {
  width: 120px;
}
.img-bg .reward1 input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.img-bg .reward1 {
  max-height: 310px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  height: 200px;
}
.img-bg .reward {
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  /* height: 295px; */
  border-radius: 10px;
}
.img-bg .reward_cert {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.img-bg .reward_cert span.insdex {
  font-size: 16px;
  font-weight: 600;
}
.img-bg form.form-outer.register button {
  position: static !important;
  border-radius: 30px;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #211d1e;
  color: #fff;
  margin-top: 31px;
  float: right;
}
img#popImg {
  width: 300px;
  display: block;
  margin: 0 auto 50px;
}
.img-bg form.form-outer.register button:hover {
  background: #25408f;
  border: 1px solid #25408f;
}
.img-bg .modelContent {
  margin-top: 20% !important;
}

div#modelContent {
  max-width: 80% !important;
  margin: 1.75rem auto !important;
  font-size: 14px;
  color: #716e6e;
}

@media (min-width: 320px) and (max-width: 767px) {
  img#popImg {
    width: 200px;
  }
}

@media (min-width: 150px) and (max-width: 321px) {
  div#modelContent {
    max-width: 190px !important;
    margin-left: 37px !important;
  }
  img#popImg {
    width: 150px;
    align-items: center;
    margin: auto;
  }
}

/*Responsive table*/
/*======
Media Queries
=======*/
/*========================*/
@media (min-width: 1200px) and (max-width: 1910px) {
  /* .container-fluid{width:60%;} */
}

@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .container-fluid {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .reward_cert img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
  .img-bg p.logo-text {
    float: right;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .img-bg ul.points li img {
    margin-bottom: 10px;
  }
  .img-bg .container-fluid {
    width: 90%;
  }
  .img-bg .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .img-bg .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .img-bg .white-box {
    text-align: center;
  }
  .img-bg .reward_cert img {
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
  .img-bg p.logo-text {
    text-align: center;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    color: #3e3934;
    margin-top: 13px;
    margin-bottom: 30px;
  }
  .img-bg .phone-outer input {
    width: 28.6% !important;
  }
  .img-bg ul.points li img {
    margin-bottom: 10px;
  }
  .img-bg .container-fluid {
    width: 90%;
  }
  .img-bg .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .img-bg .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
  div#modelContent .modal-content {
    width: 94% !important;
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .img-bg p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  .img-bg .phone-outer input {
    width: 28.6% !important;
  }
  .img-bg .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .img-bg .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .img-bg p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }
  .img-bg .phone-outer input {
    width: 28.6% !important;
  }
  .img-bg .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .img-bg .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}
.img-bg h1 {
  text-align: center;
}
.img-bg .phonenumber {
  font-size: 14px;
  font-family: Arial;
}
.img-bg .overflow-hider {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.img-bg .recap {
  width: 304px;
  height: 78px;
}
.img-bg .styling {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}
.img-bg i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.img-bg .white-box.thank svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
.img-bg ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}

/* search with select css  */
.img-bg .select_1.dropdown {
  text-align: left;
  border-radius: 30px !important;
}

.img-bg .error-select-search .css-13cymwt-control,
.img-bg .error-select-search .css-t3ipsp-control {
  border: 1px solid red !important;
}
.img-bg .css-13cymwt-control,
.img-bg .css-t3ipsp-control {
  background: transparent !important;
  border: 1px solid #1e191a !important;
  border-radius: 30px !important;
  height: 46px !important;
  margin-bottom: 10px !important;
  outline: none !important;
  padding-left: 8px;
  width: 100% !important;
  text-align: left;
}

.img-bg .react-select__input {
  height: auto !important;
}
.img-bg .react-select__menu {
  background: #f9f9f9 !important;
  color: #000 !important;
}

.img-bg .css-1xc3v61-indicatorContainer {
  color: hsl(0, 0%, 60%);
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .img-bg .container-fluid {
    width: 100%;
  }
}
.img-bg b {
  font-weight: 700;
}
.img-bg strong {
  font-weight: 900;
}
@media (min-width: 320px) and (max-width: 767px) {
  .img-bg img.card {
    margin: 0 auto !important;
  }
  .img-bg .container-fluid {
    width: 90% !important;
  }
  .img-bg .white-box {
    width: 100%;
    text-align: center;
  }
  .img-bg .white-box h1 {
    text-align: center;
    font-size: 22px;
  }
  .img-bg input#submit {
    margin-left: auto !important;
    margin-right: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-bg .container-fluid {
    width: 90% !important;
  }
  .img-bg .white-box {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .img-bg .white-box {
    width: 100%;
  }
}

@media (min-width: 1911px) and (max-width: 5000px) {
  .img-bg .container-fluid {
    width: 900px !important;
  }
  .img-bg .white-box {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .img-bg img.card {
    margin: 0 auto !important;
  }
  .img-bg .container-fluid {
    width: 90% !important;
  }
}
.img-bg label.formsub {
  max-width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: unset;
}
.img-bg .box {
  padding: 15px;
  margin: 15px;
  background-color: #00a8e2;
}

@media (min-width: 320px) and (max-width: 575px) {
  .img-bg .check-boexs {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .img-bg .container-fluid {
    width: 60% !important;
  }
}
.img-bg .header_lower {
  text-align: center;
  /* font-size: 22px; */
  font-size: 17px;
  color: #fff;
  margin-bottom: 22px;
  width: 100%;
  background-color: #3e3936;
  padding: 15px;
  margin: 0 auto;
}
.modal {
  background-color: #0000003d;
}
.term-model .modal-header {
  display: flex;
  flex-shrink: 0;
  font-family: "Raleway", sans-serif !important;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background-color: #5d5f94 !important;
  color: #fff;
}
.term-model .model-body {
  padding: 15px;
  font-size: 14px;
  color: #716e6e;
  font-family: "Raleway", sans-serif !important;
}

.img-bg :before,
.img-bg :after {
  font-family: Material-Design-Iconic-Font;
}
/* .term-model .model-body ul.terms-list li:before {
  content: "\f2fb";
  position: absolute;
  left: -3px;
  color: #3e3936;
} */
.term-model .model-body ul.terms-list {
  list-style: none;
  padding-left: 15px;
}
.term-model .model-body ul.terms-list LI {
  font-weight: 400;
  list-style-image: URL("../../public/assets/images/arrow-right.png");
}

.term-model .model-body .terms-list1 {
  list-style: none;
}
button#pop {
  border: #fff;
  background-color: #fff0;
  color: #4040bb;
  text-decoration: underline;
  margin-left: 5px;
  display: inline-block;
  width: auto;
  padding: 0;
}
button#pop:hover {
  color: #0e66e9;
}
.img-bg label.agree.label div {
  display: inline;
}
button#pop-close1 {
  background-color: #1e191a;
  border: 2px solid #fff;
  border-radius: 50%;
  font-weight: bold;
  font-size: 24px;
  margin-left: -20px;
  margin-top: -21px;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button#pop-close {
  background-color: #424586;
  border: none;
  border-radius: 50%;
  font-weight: bold;
  font-size: 24px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#modelContent .modal-content {
  box-shadow: 0 5px 15px rgb(0 0 0 / 70%);
}
div#card-section {
  margin-top: 4%;
}
div#modelContent .model-body {
  padding-right: 36px;
  padding-bottom: 15px;
  padding-top: 15px;
  font-family: Raleway, sans-serif !important;
}
div#modelContent .model-body li {
  line-height: 22px;
  font-size: 16px;
}
div#modelContent .model-body .llist {
  list-style: none;
  text-align: center;
  font-weight: 400;
}
#note,
div#modelContent .model-body .llist {
  text-align: center;
}
.img-bg #note {
  text-align: center;
}
div#modelContent .model-body .llist li a {
  color: #000;
}
div#modelContent .modal-header h4 {
  color: #fff;
  font-size: 20px;
}
#header {
  border-bottom: none !important;
  background-color: #fff !important;
}
button#closeUp {
  margin-left: -81px;
  margin-top: -55px;
}
div#modelContent .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.img-bg label.heading1 {
  display: inline;
}
.img-bg label.heading1.ml-5 {
  margin-left: 50px;
}
.img-bg label.heading1.ml-3 {
  margin-left: 80px;
}
.img-bg #card_img {
  text-align: center;
}
.img-bg .cards {
  text-align: center;
}

/* card CSS */
.img-bg .pirds {
  margin: 0;
  padding: 0;
  width: 127px;
  height: 73px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  margin-right: 3px;
  background: #2b85c6;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#2b85c6),
    to(#1f69af)
  );
  background: -webkit-linear-gradient(#2b85c6, #1f69af);
  background: -moz-linear-gradient(#2b85c6, #1f69af);
  background: -ms-linear-gradient(#2b85c6, #1f69af);
  background: -o-linear-gradient(#2b85c6, #1f69af);
  background: linear-gradient(#2b85c6, #1f69af);
}
.img-bg .form-check.form-switch .pirds {
  display: flex;
  margin: 0 auto;
}
.img-bg .rdcrdtop {
  width: 100%;
}
.img-bg .rdcrdtop table {
  border: none;
  width: 100%;
  text-align: center;
}
.img-bg .mws-table tbody td:first-child,
.img-bg .mws-table tfoot td:first-child {
  border-left: none;
}
.img-bg .rdcrdtop table tr td {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  display: block;
  line-height: 18px;
  width: 100%;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  background: none !important;
  display: table-cell;
  border-right-width: 0px !important;
}
.img-bg .rdcrd {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #f3eb31;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
  font-stretch: condensed;
  text-transform: uppercase;
}

@media (min-width: 1911px) and (max-width: 10000px) {
  .img-bg .container-fluid {
    width: 900px !important;
  }
}
.img-bg span.blue {
  word-break: break-word;
}
@media (min-width: 150px) and (max-width: 321px) {
  div#modelContent {
    max-width: 262px !important;
    margin-left: 37px !important;
  }
}
.img-bg a {
  word-break: break-word;
}
.img-bg p.register-p {
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
  font-family: var(--bs-font-sans-serif);
}
.img-bg p.thank-p {
  text-align: center;
  font-weight: 400;
  font-family: var(--bs-font-sans-serif);
}
.img-bg .alert.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  margin-top: 25px;
  font-weight: 400;
  margin-bottom: 18px;
  font-family: var(--bs-font-sans-serif);
}
.img-bg h2.register_lower {
  color: #1e191a !important;
  /* font-size: 17px !important; */
  font-size: 16px !important;
  font-weight: 800 !important;
}
