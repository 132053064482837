.login-main {
  background: #383b3f;
  font-family: "Roboto", sans-serif;
}
.login-main .box-outer {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.login-main .login-box {
  border-radius: 6px;
  margin: 0 auto;
  /*margin-top: 25vh;*/
  height: auto;
  position: relative;
  width: 40%;
  padding: 23px;
  background: #212529 !important;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-main .mws-login-lock {
  position: absolute;
  right: -15px;
  background: white;
  top: -15px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  color: #212529;
}
.login-main .login-key {
  line-height: 100px;
}
.login-main .login-key img {
  width: 60%;
}
.login-main .login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: bold;
  color: #ecf0f5;
}
.login-main .login-form {
  margin-top: 25px;
  text-align: left;
}
.login-main input[type="text"] {
  padding: 6px 12px;
  background-color: #212529;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  margin-bottom: 20px;
  padding-left: 0px;
  color: #ecf0f5;
  font-size: 16px;
}
.login-main input[type="password"] {
  padding: 6px 12px;
  background-color: #212529;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  padding-left: 0px;
  margin-bottom: 20px;
  color: #ecf0f5;
  font-size: 16px;
}
.login-main .form-group {
  margin-bottom: 40px;
  outline: 0px;
}
.login-main .form-control:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #ffffff;
  outline: 0;
  background-color: #212529;
  color: #ecf0f5 !important;
}
.login-main input:focus {
  outline: none;
  box-shadow: 0 0 0;
}
.login-main label {
  margin-bottom: 0px;
}
.login-main .form-control-label {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1px;
}
.login-main .btn-outline-primary {
  border-color: #ffffff;
  color: #ffffff;
  border-radius: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 6px 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.login-main .btn-outline-primary:hover,
.login-main .btn-outline-primary:focus,
.login-main .btn-outline-primary:active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #212529;
}
.login-main .login-btm {
  float: left;
}
.login-main .login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
}
.login-main .login-text {
  text-align: left;
  padding-left: 0px;
  color: #a2a4a4;
}
.login-main .loginbttm {
  padding: 0px;
  text-align: right;
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-main .box-outer {
    min-height: 100vh;
    height: auto;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .login-main .login-box {
    border-radius: 6px;
    margin: 0 auto;
    height: auto;
    position: relative;
    width: 80%;
    padding: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .login-main .box-outer {
    min-height: 100vh;
    height: auto;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .login-main .login-box {
    border-radius: 6px;
    margin: 0 auto;
    height: auto;
    position: relative;
    width: 97%;
    padding: 1rem;
  }
}
